<template>
  <div>
    <v-card max-width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="errors"/>
      <v-card-title>
        Schüler:in hinzufügen
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent v-model="valid">

          <!-- Firstname field -->
          <v-text-field
          label="Vorname"
          v-model.trim="firstname"
          :rules="requiredRule"
          required/>

          <!-- Lastname field -->
          <v-text-field
          label="Nachname"
          v-model.trim="lastname"
          :rules="requiredRule"
          required/>
          
          <!-- Klasse Feld -->
          <v-autocomplete
          :items = "this.classes"
          label = "Klasse"
          v-model.trim="year"
          outlined
          :rules="requiredRule"
          required
          />
  
          <!-- Schulart Feld -->
          <v-autocomplete
          :items = "this.schooltypes"
          label="Schulart"
          v-model.trim="schooltype"
          outlined
          :rules="requiredRule"
          required/>

          <!-- Name of the school field -->
          <v-text-field
          label="Schule"
          v-model.trim="school"/>

          <!-- Phone field -->
          <v-text-field 
          type="tel"
          label="Telefonnummer"
          v-model.trim="phone"
          />

          <!-- Phone of Parents field -->
          <v-text-field 
          type="tel"
          label="Telefonnummer der Eltern"
          v-model.trim="parentPhone"
          />

          <!-- E-Mail field -->
          <v-text-field
          type="email"
          label="E-Mail"
          v-model.trim="email"
          />

          <!-- Address field -->
          <v-textarea
          outlined
          rows="2"
          :rules="addressRule"
          background-color="accent"
          label="Adresse"
          v-model.trim="address"
          ></v-textarea>

          <v-divider class="my-8"/>

          <!-- Lessonaccount hours field -->
          <v-text-field 
          label="Anzahl Stunden im Stundenkonto"
          v-model.trim="manualLessons"
          :rules="digitRules"
          />

          <!-- Notizen zum Stundenkonto eg. Auservertragliche Rechnung gezahlt -->
          <v-textarea
          outlined
          auto-grow
          counter="200"
          :rules="counterRule"
          background-color="accent"
          label="Notizen Stundenkonto"
          v-model.trim="manualNotes"
          ></v-textarea>


          <v-divider class="my-8"/>


          <v-radio-group 
          label="Vertragsstatus"
          v-model="contractStatus"
          mandatory
          >
            <v-radio
              label="kein Vertrag"
              value="kein Vertrag"
            ></v-radio>
            <v-radio
              label="versendet"
              value="versendet"
            ></v-radio>
            <v-radio
              label="aktiv"
              value="aktiv"
            ></v-radio>
            <v-radio
              label="gekündigt"
              value="gekündigt"
            ></v-radio>
          </v-radio-group>

          <!-- termination Date of contract -->
          <v-text-field 
          v-if="contractStatus == 'gekündigt'"
          type="date" 
          label="gekündigt bis"
          v-model.trim="terminationDate"
          :rules="dateRule"
          required
          />

          <!-- monthly contract costs -->
          <v-text-field 
          label="Monatliche Vertragskosten"
          prefix="€"
          v-model.trim="monthlyContractCosts"
          :rules="moneyRule"
          required
          />

          <!-- costs for a single lesson without contract -->
          <v-text-field 
          label="Kosten pro (vertragsfreier) Einzelstunde"
          prefix="€"
          v-model.trim="lessonCosts"
          :rules="moneyRule"
          required
          />

        </v-form>
      </v-card-text>

      <v-card-actions>
      <v-container>
          <v-row class="my-2">
              <v-btn
              width="100%"
              color="primary"
              depressed
              :disabled="!valid"
              @click="addStudent"
              >
                Hinzufügen
              </v-btn>
          </v-row>
        </v-container>
        
    </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {db, auth, studentsCollection} from '../firebase';
import Errors from '@/components/Errors.vue';
import {getInsituteSettings} from '@/assets/js/dataUtils.js';

export default {
  name: 'AddStudent',

  components: {
    Errors,
  },

  data() {
    return {
      valid: false,
      classes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      schooltypes: [
        'Berufskolleg', 'fachliches Gymnasium', 'Förderschule', 'Gesamtschule', 
        'Grundschule', 'Gymnasium', 'Hauptschule', 'Montessori Schule', 'Realschule', 
        'Technisches Gymnasium', 'Waldorfschule', 'Werkrealschule', 
        'Wirtschaftliches Gymnasium'
      ],
      /* rules for the form */
      requiredRule: [
        v => !!v || 'Pflichtfeld',
      ],
      addressRule: [
        v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
      ],
      digitRules: [
        v => !!v || 'Gib eine Anzahl an Stunden an.',
        v => /^-?\d*(,\d)?$/.test(v) || 'Gib eine positive oder negative Zahl mit höchstens einer Kommastelle an. (ZB 2,4 oder -6,1).',
      ],
      moneyRule: [
        v => /^\d*(,\d\d)?$/.test(v) || 'Gib einen Geltbetrag an.',
      ],
      dateRule: [ //checks if the input is a string of format 'yyyy-mm-dd' and a valid date. Caution! does not test for leap years. The 29.02 is always valid.
        v => /\d{4}-(02-[0-2]\d|(01|03|05|07|08|10|12)-([0-2]\d|3[0-1])|(04|06|09|11)-([0-2]\d|30))/.test(v) || "Gib ein gültiges Datum an."
      ],
      counterRule: [
        v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
      ],
    
      firstname: '',
      lastname: '',
      year: '',
      school: '',
      schooltype: '',
      phone: '',
      parentPhone: '',
      email: '',
      address: '',
      errors: [],
      instituteId: '',
      manualLessons: '',
      manualNotes: '',
      contractStatus: '',
      terminationDate: '',
      monthlyContractCosts: '',
      lessonCosts: '',
    }
  },

  async created() {
    //Get the institueId of the currently logged in user
    this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
  },

  methods: {
    async addStudent() {

      if(this.valid) {
        this.errors = [];
        let studentRef = {};
        let studentId = '';

        const nameAlreadyExists = await this.checkIfNameAlreadyExists();

        if(!nameAlreadyExists) {
          try{
            /* Add the new Student to Firestore */
            await studentsCollection.add({
              firstname: this.firstname,
              lastname: this.lastname,
              year: this.year,
              school: this.school,
              schooltype: this.schooltype,
              phone: this.phone,
              parentPhone: this.parentPhone,
              email: this.email,
              address: this.address,
              balanceLessonAccount: 0,
              instituteId: this.instituteId,
              contractStatus: this.contractStatus,
              terminationDate: this.terminationDate,
              monthlyContractCosts: this.monthlyContractCosts,
              lessonCosts: this.lessonCosts,
            })
            .then(docRef => {
              studentRef = db.doc(docRef.path);
              studentId = docRef.id;
            })
            .catch((error) => {
              console.log("Error updating documents: ", error);
              this.errors.push({
                text: 'Die Schülerin / der Schüler konnte nicht hinzugefügt werden.', 
                type:'firestore',
              });
              window.scrollTo(0,0);
            })

            //add the new student to prefillAddEvent, so that it is prefilled when going back there
            this.$store.commit('setprefillAddEvent', {
              key: 'student', 
              value: {
                name: this.firstname + ' ' + this.lastname, 
                ref: studentRef,  
                year: this.year,
                schooltype: this.schooltype,
                id: studentId,
                }
              });

            /* Add the lessons to the lessonaccount */
            if(this.manualLessons != 0){
              try{
                const minEventDuration = (await getInsituteSettings()).minEventDuration;

                await this.$store.dispatch('editLessonAccount', {
                  studentId: studentId, 
                  minutes: parseInt(this.manualLessons) * parseInt(minEventDuration), 
                  notes: '[Manuell] Beim Anlegen des Schülers hinzugefügt. ' + this.manualNotes, 
                  date: new Date().toISOString().substr(0,10), //today
                  subject: '', 
                  teacherName: ''
                })

              } catch {
                this.errors.push({
                  text: 'Die Stunden konnten nicht zum Stundenkonto hinzugefügt werden. Der Schüler wurde aber angelegt.',
                  advice: 'Bitte trage die Stunde nachträglich ein.',
                })
               }
            }

            this.$router.go(-1);

          } catch(error) {
            console.log(error);
          }
        } else {
          this.errors.push({
            text: 'Es existiert bereits ein Schüler / eine Schülerin mit diesem Namen.',
            advice: 'Wähle einen anderen Namen.',
          })
        }
      }
    },

    async checkIfNameAlreadyExists() {

      let nameAlreadyExists = false;

      /* get all Studentnames */

      await studentsCollection
        .where('instituteId', '==', this.instituteId)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            //check if this student has the same first and last name as in the form
            if(doc.data().firstname == this.firstname && doc.data().lastname == this.lastname) {
              nameAlreadyExists = true;
            }
          })
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          this.errors.push({
            text: 'Die Schüler:innen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        });

      return nameAlreadyExists;
    },
    
  }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>